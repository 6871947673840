import './assets/css/main.css';
import './assets/css/font-awesome.min.css';
import '../css/style.css';

// window.$ = require('jquery');
// require('./assets/js/jquery.min.js')
require('./assets/js/jquery.scrolly.min.js')
require('./assets/js/skel.min.js')
require('./assets/js/util.js')
require('./assets/js/main.js')
require('../js/nav.js')


import './assets/fonts/fontawesome-webfont.eot';
import './assets/fonts/fontawesome-webfont.svg';
import './assets/fonts/fontawesome-webfont.ttf';
import './assets/fonts/fontawesome-webfont.woff';
import './assets/fonts/fontawesome-webfont.woff2';
import './assets/fonts/FontAwesome.otf';

import './images/banner.jpg';
import './images/pic01.jpg';
import './images/pic02.jpg';
import './images/pic03.jpg';
import './images/pic04.jpg';
import './images/pic05.jpg';
import './images/api-wf-yellow.png';
import './images/api-wf-og-image.png'


// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/solid'
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'


// Check if HMR interface is enabled
if (module.hot) {
    // Accept hot update
    module.hot.accept();
}