import '../images/logos/api-wf-logo-white.png';

// Production
var clientId = '17kl4dobvlhnrqtkogdkbj1cv4'; //remote: '17kl4dobvlhnrqtkogdkbj1cv4', local: '1li69iobmfppfaelggv3jeihv0'
var homePage = 'https://www.apiworkflow.io'; // 'https://www.apiworkflow.io', 'http://localhost:8080'

// Local
// var clientId = '1li69iobmfppfaelggv3jeihv0'; //remote: '17kl4dobvlhnrqtkogdkbj1cv4', local: '1li69iobmfppfaelggv3jeihv0'
// var homePage = 'http://localhost:8080'; // 'https://www.apiworkflow.io', 'http://localhost:8080'


$(document).ready(function() {
	$("#nav li:has(ul) > a").click(function() {
	  var $ul = $(this).next("ul").toggleClass("show-subnav");

	  // commented out following two lines which hides an already open 
	  // submenu when a second submenu is clicked.
	  //$ul.find('ul.show-subnav').removeClass('show-subnav');
	  //$('#nav ul.show-subnav').not($ul.parentsUntil('#nav', 'ul').add($ul)).removeClass('show-subnav')
	});
});

window.login = function() {
    var loginUrl = 'https://auth-apiworkflow.auth.us-east-1.amazoncognito.com/login?client_id=' + clientId + '&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+homePage+'/dashboard';
    window.location.href = loginUrl;
}

window.logout = function() {
	var logoutUrl = 'https://auth-apiworkflow.auth.us-east-1.amazoncognito.com/logout?client_id=' + clientId + '&logout_uri='+homePage; //logout_uri
	window.location.href = logoutUrl;
}

window.signup = function() {
	var signUp = "https://auth-apiworkflow.auth.us-east-1.amazoncognito.com/signup?client_id=" + clientId + "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + homePage + "/dashboard";
	window.location.href = signUp;
}

window.goToProfilePage = function() {
	// var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/profile';
}


window.openNav = function() {
	document.getElementById("mySidenav").style.visibility='visible';
	if ( document.getElementById("mySidenav").style.width === '250px') {
		document.getElementById("mySidenav").style.width = "0";
		//document.getElementById("main").style.marginLeft= "0";
		document.body.style.backgroundColor = "white";
	} else {
		document.getElementById("mySidenav").style.width = "250px";
		//document.getElementById("main").style.marginLeft = "250px";
		//document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
	}
}

window.closeNav = function() {
  document.getElementById("mySidenav").style.width = "0";
  //document.getElementById("main").style.marginLeft= "0";
  document.body.style.backgroundColor = "white";
}


window.showApiConfig = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	//window.location.href =  '/gatewaymonitor.html#'+_cognitoToken;
	window.location.href =  '/api-connect';
}



window.showAPIConfigList = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/configured-apis-list';
}

window.showPropertyEditor = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/property-editor';
}

window.showNamespaceEditor = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/namespace-editor';
}

window.showWebhookEditor = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/webhook';
}


window.showAPIExecutionData = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/apis-exec-data';
}


window.showWorkflowExecutionData = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/workflow-exec-data';
}

window.showWebhookExecutionData = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/webhook-calls-data';
}

window.showDashboard = function() {
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	window.location.href =  '/dashboard';
}

window.showInfoBox = function(__homeDir) {

	console.log('showInfoBox :: __homeDir = ' + __homeDir);
	if( typeof __homeDir === 'undefined' || __homeDir == null || __homeDir == '') {
		window.location.href =  '/databox';
	} else {
		// fixing databox not honoring the query parameter issue by adding the trailing slash i.e. '/'
		// trailing '/' is required with the url, otherwise aplify doesn't forward the query parameters
		// Ref: https://docs.aws.amazon.com/amplify/latest/userguide/redirects.html >> Trailing slashes and clean URLs
		window.location.href =  '/databox/?homeDir=' + __homeDir;
	}

}


window.showOAuth20 = function() {
//	window.location.href =  '/oauth2.html#';
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	//window.location.href =  '/oauth2.html#'+_cognitoToken;
	window.location.href =  '/oauth-connect';
}    



window.showAppConnections = function() {
//	window.location.href =  '/oauth2.html#';
	var _cognitoToken = window.sessionStorage.getItem("cognitoToken")
	//window.location.href =  '/oauth2.html#'+_cognitoToken;
	window.location.href =  '/app-connect';
}  

window.showWorkflowConfigurator = function() {
    window.location.href =  '/workflow-configurator';
}  

window.showWorkflowList = function() {
    window.location.href =  '/workflow-list';
} 